// @flow
import UAParser from 'ua-parser-js';
import * as React from 'react';
import { Provider } from 'react-redux';
import * as ReactDOM from 'react-dom';
import { Router, type ContextRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import createHistory from 'history/createBrowserHistory';
import qs from 'qs';
import axios from 'axios';
import { values, flatten, compose, map } from 'ramda';
import { translationsLoaderPromise } from '../_t-polyfill.js';
import { createKoltronStore } from '../createKoltronStore';
import {
  INITIAL_STATE_VAR,
  RUNTIME_CONFIG,
  REGISTRATION_BONUS_COOKIE_KEY,
  MOBILE,
  TABLET,
  DESKTOP,
} from '../../common/constants';
import { ScrollToTopOnPathChange } from '../components/ScrollToTopOnPathChange.js';
import {
  getJSSTheme,
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type GetJSSThemePatternsType,
} from './JSSThemeDefault';
import preset from 'jss-preset-default';
import { create as createJss } from 'jss';
import { generateJssFontsSrc } from './jss-helpers.js';
import { ReduxContext } from '../redux-hooks.js';
import { JSSThemeContext, useJSSResets } from '../jss-hooks.js';
import { checkKoltronVersion } from '../checkKoltronVersion.js';
import { OfflineMessage } from '../components/OfflineMessage.js';
import { GeoblockPageAll } from './GeoblockPageAll';
import { AffiliatesAll } from './AffiliatesAll';
import IdleTimerComponent from '../components/components/IdleTimerComponent';
import { PersistGate } from 'redux-persist/integration/react';
import AnalyticsTracker from '../components/analytics/AnalyticsTracker';

/** Koltron devTool sidebar. Work on test or dev mode */
let DevToolsSideBar = () => null;
const showDevTools = window.__OSG_RUNTIME_CONFIG__.showDevTools;

if (showDevTools) {
  DevToolsSideBar = React.lazy(() =>
    import('../components/koltron-dev-tools/sidebar-components/SideBar')
  );
}

const jss = createJss();
jss.setup(preset());

const deviceType = new UAParser().getDevice().type;

const ResetAttacher = () => {
  useJSSResets();
  return null;
};

window.__OSG_RUNTIME_CONFIG__.userDevice =
  deviceType === MOBILE || deviceType === TABLET ? MOBILE : DESKTOP;
window.__OSG_RUNTIME_CONFIG__.isDesktop =
  window.__OSG_RUNTIME_CONFIG__.userDevice === DESKTOP;

const { isDesktop } = window.__OSG_RUNTIME_CONFIG__;

const checkForGeoAccess = async () => {
  if (window.__OSG_RUNTIME_CONFIG__.geoIPUrl) {
    try {
      const response = await axios.get('/api/geofilter');
      window[INITIAL_STATE_VAR].geoData =
        response.data && response.data.geoData;
      if (response.data && response.data.allowAccess === false) return false;
    } catch (error) {}
  }
  return true;
};

export const createKoltronApp = async ({
  cb = () => {},
  MobileComponent,
  DesktopComponent,
  JSSThemeVariables,
  JSSTheme,
  JSSThemePatterns,
  GeoblockPage = GeoblockPageAll,
  Affiliates = AffiliatesAll,
}: {
  cb?: (void) => void,
  DesktopComponent: React.AbstractComponent<$Shape<ContextRouter>>,
  MobileComponent: React.AbstractComponent<$Shape<ContextRouter>>,
  JSSThemeVariables: JSSThemeVariablesShapeType,
  JSSTheme: JSSThemeShapeType,
  JSSThemePatterns: GetJSSThemePatternsType,
  GeoblockPage?: React.AbstractComponent<{}>,
  Affiliates?: React.AbstractComponent<{}>,
} = {}) => {
  const { store, persistor } = createKoltronStore(
    window[INITIAL_STATE_VAR],
    window[RUNTIME_CONFIG]
  );

  // Check if user is allowed to visit site by its geoIP
  const allowAccess = await checkForGeoAccess();

  const Component = isDesktop ? DesktopComponent : MobileComponent;

  const history = createHistory();

  if (window.location.search) {
    const bonusKey = REGISTRATION_BONUS_COOKIE_KEY;
    const bonus = qs.parse(window.location.search, { ignoreQueryPrefix: true })[
      bonusKey
    ];
    if (bonus) {
      Cookies.set(bonusKey, bonus, {
        expires: 7,
        sameSite: 'None',
        secure: true,
      });
    }
  }

  const locale = window.__OSG_RUNTIME_CONFIG__.locale;
  if (locale) {
    Cookies.set(window.__OSG_RUNTIME_CONFIG__.langCookieName, locale, {
      expires: 365,
    });
    // $FlowFixMe
    document.documentElement.setAttribute('lang', locale.slice(0, 2));
  }
  const theme = getJSSTheme(JSSThemeVariables, JSSTheme, JSSThemePatterns);

  jss
    .createStyleSheet({
      '@global': {
        '@font-face': compose(
          flatten,
          map(({ name, config }) =>
            config.map(({ path, fontWeight, fontStyle }) => ({
              fontFamily: name,
              src: generateJssFontsSrc(path),
              fontWeight,
              fontStyle,
              fontDisplay: 'swap',
            }))
          ),
          values
        )(theme.fonts),
        body: {
          fontFamily: theme.fonts.default.fontFamily,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontVariant: 'normal',
        },
      },
    })
    .attach();

  await translationsLoaderPromise;

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReduxContext.Provider value={store}>
          {/* $FlowFixMe react-router-dom Router history prop type is incompatible with BrowserHistory wtf */}
          <Router history={history}>
            <AnalyticsTracker />
            <JSSThemeContext.Provider value={{ theme, jss }}>
              {allowAccess ? (
                <React.Fragment>
                  <ScrollToTopOnPathChange />
                  <OfflineMessage />
                  <IdleTimerComponent />
                  <ResetAttacher />
                  <Component />
                  {showDevTools && (
                    <React.Suspense fallback={<></>}>
                      <DevToolsSideBar />
                    </React.Suspense>
                  )}
                </React.Fragment>
              ) : (
                <GeoblockPage />
              )}
            </JSSThemeContext.Provider>
          </Router>
        </ReduxContext.Provider>
      </PersistGate>
    </Provider>,
    // $ExpectError
    document.querySelector('#osg-app'),
    cb
  );

  return { store };
};

function pollVersion() {
  setTimeout(() => {
    checkKoltronVersion().then(pollVersion, pollVersion);
  }, 10 * 60 * 1000);
}

pollVersion();

window.__OSG_VERSION__ = __OSG_VERSION__;
