import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { dataLayerPushObject } from '../../utils';
import { useUserInfo } from '../../redux-hooks';

const AnalyticsTracker = () => {
  const location = useLocation();
  const info = useUserInfo();
  const [previousUrl, setPreviousUrl] = useState(null);

  const accountNumber = info?.accountNumber;

  useEffect(() => {
    if (location.pathname !== previousUrl) {
      dataLayerPushObject({
        eventName: 'PageViewWithGUID',
        data: {
          GUID: accountNumber || null,
          previousPage: previousUrl,
          currentPage: location.pathname,
        },
      });
      setPreviousUrl(location.pathname);
    }
  }, [location, previousUrl, accountNumber]);

  return null;
};

export default AnalyticsTracker;
